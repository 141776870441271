body
  font-weight: 500

$theme-green: #3bd2a2
.top-sep-green
  border-top: 2px solid $theme-green
.green-color
  color: $theme-green
.green-bg
  background-color: $theme-green

.top-sep-white
  border-top: 2px solid white

$theme-blue: #6290b2
.top-sep-blue
  border-top: 2px solid $theme-blue
.blue-color
  color: $theme-blue
.blue-bg
  background-color: $theme-blue

$theme-light-blue: #B8cedf

$theme-dark-blue: #132f47
.dark-blue-color
  color: $theme-dark-blue
.dark-blue-bg
  background-color: $theme-dark-blue
